.dialog {
  :global {
    .MuiDialogTitle-root {
      @apply p-8;
    }

    .MuiDialogContent-root {
      @apply px-8;
    }

    .MuiDialogActions-root {
      @apply px-8 pb-8;
    }
  }
}

.btn-dismiss {
  @apply font-medium text-primary;

  min-width: 5rem;

  &:global(.disabled) {
    @apply text-textSecondary;

    pointer-events: none;
  }
}
