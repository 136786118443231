.header {
  @apply bg-primary text-white flex-none mb-auto;

  background: linear-gradient(90deg, rgba(0, 116, 166, 1) 0%, rgba(0, 131, 143, 1) 100%);

  > :global(.container-fluid) {
    @apply mx-auto px-gutter-1/2;
  }

  :global(.row) {
    @apply items-center flex-no-wrap py-4;
  }
}

.col.drawer {
  @apply col;

  flex: 0;

  :global {
    #btn-app-drawer {
      @apply ml-gutter-1/2 mr-2 p-0;
    }
  }
}

.drawer-title {
  @apply flex items-center pb-8;

  > a {
    @apply block leading-6;

    &:first-child {
      filter: brightness(2);
    }

    &:last-child {
      @apply font-black ml-8 text-white text-xl;

      flex: 1;
      letter-spacing: 0.22em;
    }
  }
}

:global {
  #app-drawer {
    .MuiDrawer-paper {
      @apply p-6;

      background: linear-gradient(180deg, theme('colors.allports') 0%, theme('colors.blueLagoon') 100%);
      min-width: 320px;
      max-width: 320px;
    }

    .MuiExpansionPanel-root {
      @apply bg-transparent text-white;

      &:not(:first-child) {
        @apply border-t;

        border-top-color: rgba(255, 255, 255, 0.3);
      }

      &:before {
        @apply opacity-0;
      }

      &.Mui-expanded {
        @apply my-0;
      }
    }

    .MuiExpansionPanelSummary-root {
      @apply min-h-0 px-0 py-5;
    }

    .MuiExpansionPanelSummary-content {
      @apply items-center my-0;
    }

    .MuiExpansionPanelSummary-expandIcon {
      @apply p-0 mr-0;
    }

    .MuiExpansionPanelDetails-root {
      @apply p-0;

      > div {
        @apply w-full;
      }
    }

    .MuiIconButton-label {
      @apply text-white;
    }

    .MuiAvatar-root {
      @apply mr-4;

      height: 3.5rem;
      width: 3.5rem;
    }

    .MuiBadge-badge.MuiBadge-anchorOriginBottomRightCircle {
      bottom: 20%;
      right: 20%;

      .MuiAvatar-img {
        @apply h-6 w-6;
      }
    }
  }
}

.nav-user-account {
  @apply flex flex-col list-none mb-5 px-4 text-sm text-white w-full;

  > .item {
    > .link {
      @apply flex font-light align-middle py-4 tracking-wider;

      &[aria-current='page'] {
        @apply font-bold;
      }

      img {
        @apply align-middle flex-none self-center mr-8 w-5;

        max-height: theme('width.5');
        filter: brightness(2);
      }
    }
  }
}

.nav-folder-list {
  @apply flex flex-col list-none mb-5 px-4 text-sm text-white w-full;

  > .item {
    > .link {
      @apply flex font-light align-middle py-4 tracking-wider;

      &[aria-current='page'] {
        @apply font-bold;
      }

      img {
        @apply align-middle flex-none self-center mr-8 w-5;

        max-height: theme('width.5');
        filter: brightness(2);
      }

      > .folder-name {
        @apply flex-none overflow-hidden whitespace-no-wrap;

        max-width: 7rem;
        text-overflow: ellipsis;
      }

      > .folder-item-count {
        @apply flex-1 text-right;
      }

      &:global(.indented) {
        padding-left: 3.25rem;
        padding-top: theme('padding.3');

        img {
          @apply mr-2;
        }
      }
    }
  }

  + .nav-folder-list {
    @apply -mt-5;
  }
}

.action-link {
  @apply block border-t py-5 text-white;

  border-top-color: rgba(255, 255, 255, 0.3);
}

.col.logo {
  @apply col flex-1 whitespace-no-wrap;

  > a {
    @apply align-middle inline-block font-black text-white leading-5;

    letter-spacing: 0.22em;
  }
}

.col.a11y {
  @apply sr-only;
}

.col.nav {
  @apply hidden;
}

:global(.root) > .header > :global(.container-fluid) {
  max-width: none;
}

@screen sm {
  .col.nav {
    @apply block col text-right text-sm;
  }
}

@media (min-width: 768px) {
  .col.nav {
    @apply pr-8;

    flex: 0;
  }
}

@screen md {
  .col.logo {
    @apply pl-10;
  }
}

@media print {
  .header {
    display: none !important;
  }
}
