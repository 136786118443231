.form {
  composes: form-base from '../shared/forms.module.css';

  > :global(.container-fluid) {
    @apply mx-auto;
  }

  :global(.row) {
    @apply flex-col mx-auto px-gutter-1/2;
  }
}

.heading1 {
  @apply h1 text-center mb-2;
}

.leadText {
  @apply lead text-center mb-12;
}

.btn-submit {
  composes: btn-submit from '../shared/forms.module.css';

  @apply my-5 w-full;
}

.action-forgot-password {
  @apply font-medium text-sm text-primary text-center w-full;

  &:global(.disabled) {
    @apply text-textDisabled;
  }
}

.notReceivedCodeQuestion {
  @apply inline-block mr-1 pb-6 text-secondary;
}

.resentCodeAction {
  @apply inline-block text-primary;
}

@screen sm {
  .form :global(.row) {
    max-width: 50%;
  }
}

@screen md {
  .form :global(.row) {
    max-width: 33.33333%;
  }
}
