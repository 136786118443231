.root,
:global(.root) {
  @apply flex flex-col flex-no-wrap h-full m-0 p-0 w-full;
}

.nav-header {
  @apply flex flex-no-wrap items-center list-none justify-end;

  > li:last-child > .link {
    @apply inline-flex flex-no-wrap items-center;
  }
}

.link {
  @apply block whitespace-no-wrap mr-4;

  &[data-action-id='InviteGuest'] {
    @apply bg-auto bg-no-repeat border text-xs;

    background-image: url('../../assets/images/action-invite-patient.min.svg');
    background-position: 12px center;
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    line-height: 1rem;
    padding: 6px 12px 6px 32px;
  }
}

.nav-header :global(#btn-user-account-menu) {
  @apply outline-none whitespace-no-wrap;
}

.nav-header :global(.MuiAvatar-root) {
  @apply align-middle font-normal h-8 inline-flex ml-3 text-sm w-8;
}

.nav-header :global(.MuiBadge-badge) {
  @apply px-0;
}

:global(#user-account-menu .MuiMenuItem-root) {
  @apply px-8 py-3 text-secondary text-sm;

  &:hover {
    @apply font-medium;
  }
}

.nav-footer {
  @apply flex flex-no-wrap list-none justify-around;

  .item {
    @apply flex flex-no-wrap items-center;

    &:last-child {
      .link {
        @apply mr-0;
      }
    }
  }
}

.empty-footer {
  @apply flex-none mt-auto;
}

@screen sm {
  .nav-footer {
    @apply justify-end;
  }
}

@media (min-width: 768px) {
  .nav-header .link,
  .nav-footer .link {
    @apply mx-5;
  }

  .nav-header :global(#btn-user-account-menu) {
    @apply ml-5 outline-none whitespace-no-wrap;
  }
}
