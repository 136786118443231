.nav {
  @apply flex list-none;
}

.link {
  @apply block py-2 px-gutter-1/2 my-2 mx-0;
}

.item:last-child .link {
  @apply pr-0;
}
