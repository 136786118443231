.footer {
  @apply bg-white text-primary text-xs flex-none mt-auto;

  > :global(.container-fluid) {
    @apply mx-auto px-gutter-1/2;
  }

  :global(.row) {
    @apply border-t border-silver items-start py-5;
  }
}

.col.nav {
  @apply col-12 mb-5 w-auto;
}

.col.notice {
  @apply col-12 font-bold text-center text-doveGray whitespace-no-wrap w-auto;
}

@screen sm {
  .col.nav {
    @apply flex-1;
  }

  .col.notice {
    @apply flex-none pl-0 text-right;
  }
}

@media print {
  .footer {
    display: none !important;
  }
}
