.form {
  composes: form-base from '../../../app/shared/forms.module.css';

  .fieldset-health-plan {
    @apply mt-2;

    > legend {
      @apply flex font-medium items-center pb-5 text-textSecondary w-full;

      &::before {
        @apply bg-textSecondary mr-2;

        content: '';
        height: 1px;
        width: theme('width.2');
      }

      &::after {
        @apply bg-textSecondary flex-grow ml-2;

        content: '';
        height: 1px;
      }
    }

    > div {
      @apply row;
    }
  }
}

.form-input-group {
  composes: form-input-group from '../../../app/shared/forms.module.css';

  @apply col-12;
}

.heading-success {
  @apply border-b border-alto font-medium text-success mb-8 pb-8 pt-10 pr-20 w-full;

  :global(.MuiSvgIcon-root) {
    @apply border-2 mr-3 align-middle;

    border-radius: 50%;
  }
}

.access-code-label {
  @apply text-textPrimary mb-2 w-full;
}

.access-code {
  @apply h1 pb-6 w-full;

  letter-spacing: 0.35em;
}

@screen sm {
  .form-input-group:not(:global(.email)) {
    @apply col-6;
  }
}
