.form-base {
  :global {
    .MuiFormControl-root:hover {
      .MuiFormLabel-root:not(.Mui-error) {
        @apply text-textPrimary;
      }
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      @apply border;
    }

    .Mui-error:hover .MuiOutlinedInput-notchedOutline {
      @apply border-2;
    }

    .MuiFormHelperText-root.Mui-error {
      @apply hidden text-danger text-sm mx-0 w-full;
    }

    .MuiInputLabel-marginDense,
    .MuiInputBase-inputMarginDense {
      @apply text-sm;
    }

    .MuiInputAdornment-root > .MuiButtonBase-root {
      @apply p-0;

      &:hover {
        @apply bg-transparent;
      }
    }
  }

  &:not(:global(.was-validated)) {
    :global {
      .MuiFormControl-root {
        .MuiFormLabel-root {
          @apply text-textSecondary;
        }

        .MuiInput-underline {
          &:before,
          &:after {
            @apply border-b border-textSecondary;
          }
        }

        .MuiOutlinedInput-root {
          .MuiOutlinedInput-notchedOutline {
            @apply border-textSecondary;
          }
        }

        &:hover {
          .MuiFormLabel-root {
            @apply text-textPrimary;
          }

          .MuiInput-underline:after {
            @apply border-textPrimary;
          }

          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              @apply border-textPrimary;
            }
          }
        }
      }
    }
  }

  &:global(.was-validated) {
    :global {
      .MuiFormControl-root {
        .MuiFormLabel-root:not(.Mui-error) {
          @apply text-textPrimary;
        }
      }

      .MuiInput-underline:not(.Mui-error):after {
        @apply border-b border-textPrimary;
      }

      .MuiOutlinedInput-root:not(.Mui-error) {
        .MuiOutlinedInput-notchedOutline {
          @apply border border-textSecondary;
        }

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          @apply border-secondaryLight;
        }

        &:hover .MuiOutlinedInput-notchedOutline {
          @apply border-textPrimary;
        }
      }

      .MuiFormHelperText-root.Mui-error {
        @apply block;
      }
    }
  }
}

.form-input-group {
  @apply mb-5;

  > div {
    @apply w-full;
  }
}

.btn-submit {
  @apply btn btn-sm btn-primary btn-rounded font-medium text-sm;

  min-width: 10rem;

  &:focus,
  &:hover {
    @apply bg-primaryDark border-primaryDark text-white;

    outline-color: theme('colors.primaryDark');
  }

  &:disabled,
  &:global(.disabled) {
    @apply cursor-auto pointer-events-none text-textSecondary;

    background-color: rgba(0, 0, 0, 0.12);
  }

  &:global(.lds-dual-ring):before {
    border-color: theme('colors.textSecondary') transparent;
  }
}
